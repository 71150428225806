import { Component, inject, effect } from '@angular/core';
import { AuthService, GenericError } from '@auth0/auth0-angular';
import { NS_CONFIG_TOKEN } from '@bannerflow/notification';
import { filter, mergeMap } from 'rxjs';
import { RouterOutlet } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent {
    private readonly authService = inject(AuthService);
    private readonly nsConfig = inject(NS_CONFIG_TOKEN);
    private readonly user = toSignal(this.authService.user$);

    constructor() {
        this.authService.error$
            .pipe(
                takeUntilDestroyed(),
                filter(
                    (e) =>
                        e instanceof GenericError &&
                        (e.error === 'login_required' || e.error === 'invalid_grant')
                ),
                mergeMap(() => this.authService.logout())
            )
            .subscribe();

        effect(
            () => {
                const user = this.user();
                if (user) {
                    this.authService
                        .getAccessTokenSilently({ cacheMode: 'on' })
                        .subscribe((accessToken) => {
                            this.nsConfig.accessToken$.next(accessToken);
                        });
                }
            },
            {
                allowSignalWrites: true
            }
        );
    }
}
