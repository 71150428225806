import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAppState } from '@campaign/pages/list/store/ad-list.selectors';
import { selectUserState } from '@core/core.reducer';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { User } from '@shared/models/bannerflow';
import { IPixel } from '@shared/models/bannerflow/user/iPixel.model';
import { ILocalization, ISize } from '@shared/models/common';
import { Observable, filter, map, take } from 'rxjs';
import { BannerflowTypeGuards } from './bannerflow-type-guards';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class BannerflowApiService {
    public v2Prefix: string;
    private accountAccessLocalizationsUrl: string;
    private brandManagerUrl: string;
    private brandId: string | null = null;
    private accountAccessUrl: string;
    constructor(
        private readonly http: HttpClient,
        store: Store<IAppState>,
        private readonly userService: UserService
    ) {
        store
            .select(selectUserState)
            .pipe(
                filter((userState) => userState && !!userState.user),
                map((userState) => userState.user),
                take(1)
            )
            .subscribe((user) => {
                const { brandManagerUrl, accountAccessUrl } = environment;
                const accountSlug = user.account.slug;
                const brandSlug = user.brand.slug;

                this.brandId = user.brand.id;
                this.accountAccessLocalizationsUrl = `${accountAccessUrl}/api/brand/current/${accountSlug}/${brandSlug}/localizations`;
                this.brandManagerUrl = brandManagerUrl;
                this.accountAccessUrl = `${accountAccessUrl}`;
            });
    }

    public getUser(accountSlug: string, brandSlug: string): Observable<User> {
        const url = `${environment.apiGatewayUrl}/account-access/api/${accountSlug}/${brandSlug}/users/current`;
        return this.http
            .get(url, { params: { origin: 'campaignmanager' } })
            .pipe(map((response: any) => new User().deserialize(response)));
    }

    public getLocalizations(): Observable<ILocalization[]> {
        const url = this.accountAccessLocalizationsUrl;
        return this.http.get(url).pipe(
            map((response: any) => {
                if (response.some((loc) => !BannerflowTypeGuards.isLocalization(loc))) {
                    throw new TypeError('object does not match ILocalization');
                }

                return response as ILocalization[];
            })
        );
    }

    public getSizes(): Observable<ISize[]> {
        const brandId = this.brandId;
        if (!brandId) {
            throw new Error('Brand ID is not available.');
        }
        const url = `${this.brandManagerUrl}/brand/${brandId}/size-formats`;
        return this.http.get(url).pipe(
            map((response: any) =>
                response.map((sizeFormat: any) => {
                    if (!BannerflowTypeGuards.isSizeFormat(sizeFormat)) {
                        throw new TypeError('object does not match ISizeFormat');
                    }
                    return {
                        ...sizeFormat.size,
                        name: sizeFormat.name,
                        id: sizeFormat.id
                    };
                })
            )
        );
    }

    public getPixels(): Observable<IPixel[]> {
        const url = `${this.accountAccessUrl}/${this.brandId}/brands/pixels`;
        return this.http.get<IPixel[]>(url);
    }
}
