import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule, Title } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideSentinelService, withNewRelic } from '@bannerflow/sentinel';
import { UIModule } from '@bannerflow/ui';
import { GlobalErrorHandler } from '@core/services/monitoring/global-error-handler.service';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { CoreModule } from './app/core/core.module';
import { environment } from './environments/environment';
import { provideFeatureFlags } from '@bannerflow/feature-flags';
import { provideNotificationService } from '@bannerflow/notification';
import { BehaviorSubject } from 'rxjs';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, CoreModule, AppRoutingModule, UIModule),
        Title,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideSentinelService(
            withNewRelic({
                ...environment.newRelic,
                applicationVersion: environment.version,
                releaseName: environment.version,
                releaseId: environment.version
            })
        ),
        provideAnimations(),
        provideFeatureFlags({
            enabled: environment.featureFlags.enabled,
            appName: 'Campaign Manager',
            url: environment.featureFlags.url,
            clientKey: environment.featureFlags.clientKey
        }),
        provideNotificationService({
            apiUri: environment.notificationServiceApi,
            accessToken$: new BehaviorSubject<string | undefined>(undefined),
            signalR: {
                enabled: environment.NSSignalR.enabled,
                url: environment.NSSignalR.url
            }
        })
    ]
}).catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
});
